<template>
  <div>
    <el-row>
      <el-col :span="24">
        <img :src="bg" alt="123" class="resize" style="margin-bottom: -7px;">
        <div class="navbar">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/support' }">支持</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" style="padding: 0 25%">
        <!-- <div class="intro">
          <el-link href="/#/home/ofenanswer" :underline="false">
            <img :src="tool" alt="tool" style="width: 50px;">
            <p>产品支持</p>
          </el-link>
          <el-link :underline="false" href="#" style="margin-left: 120px;">
            <img :src="download" alt="download" style="width: 50px;">
            <p style="margin-top: 5px;">下载</p>
          </el-link>
        </div> -->
        <div class="intro_goods" style="margin-top: 60px;">
          <img :src="showGoods_1" alt="one" class="resize" style="max-width: 450px; max-height: auto;">
          <div>
            <p style="font-size: 25px; font-weight: 500; margin-top: 80px;">了解CHIGEE产品旗下APP</p>
            <p style="font-size: 15px; text-align: left; margin-top: 15px;">立即下载APP<br />
              远程监控随时随地掌控你的爱车</p>
            <p style="text-align: left; margin-top: 50px;">
              <el-link :underline="false" href="#/home/support_download">
                <div class="goods_1_link"><span>了解更多信息</span><span class="el-icon-right"></span></div>
              </el-link>
            </p>
          </div>
        </div>
        <div class="intro_goods" style="margin-top: 150px;">
          <div>
            <p style="font-size: 25px; font-weight: 500; margin-top: 80px;">了解CHIGEE产品旗下APP</p>
            <p style="font-size: 15px; text-align: left; margin-top: 15px;">在此处观看所有CHIGEE产品教程视频</p>
            <p style="text-align: left; margin-top: 50px;">
              <el-link :underline="false" href="#/home/support_video">
                <div class="goods_1_link"><span>了解更多信息</span><span class="el-icon-right"></span></div>
              </el-link>
            </p>
          </div>
          <img :src="showGoods_2" alt="two" class="resize" style="max-width: 450px; max-height: auto;">
        </div>
        <div class="main">
          <div style="height: 140px; line-height: 180px; text-align: center; font-size: 30px;">常见问题</div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="AIO-5" name="first">
              <el-collapse v-model="apse_active" accordion>
                <el-collapse-item :title="item.title" :name="item.name" v-for="item in apseData" :key="item.name">
                  <div>{{item.answer}}</div>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane label="CG-2" name="second">配置管理</el-tab-pane>
            <el-tab-pane label="XR-3" name="third">角色管理</el-tab-pane>
            <el-tab-pane label="G3" name="fourth">定时任务补偿</el-tab-pane>
          </el-tabs>
          <div class="main_btm">
            <el-button type="primary" round><span>
                <el-link :underline="false" href="#/home/ofenanswer" style="color: #fff;">了解更多信息</el-link>
              </span> <span class="el-icon-right"></span></el-button>
            <p style="font-size: 35px; margin-top: 50px;">联系我们</p>
            <img :src="erwei" alt="二维码" style="width: auto; height: auto; max-weight: 120px; max-height: 120px;">
          </div>
        </div>
      </el-col>
    </el-row>

  </div>

</template>
<script>
export default {
  components: {},
  data() {
    return {
      bg: require('../../../public/images/aio/G3.png'),
      tool: require('../../../public/images/support/tool.png'),
      download: require('../../../public/images/support/download.png'),
      showGoods_1: require('../../../public/images/support/showGoods_1.png'),
      showGoods_2: require('../../../public/images/support/showGoods_2.png'),
      activeName: 'first', // 分段器
      apse_active: '1', // 手风琴
      apseData: [
        {
          name: '1',
          title: '记录仪为什么有好几天没录制到呢？',
          answer: '请检查内存卡是否损坏，如仍无法解决可尝试再次进行格式化内存卡。'
        },
        {
          name: '2',
          title: 'OBD模块怎么安装?',
          answer: '需将OBD模块插入ECU检测口, 并将OBD模块中黄色ACC线接入车身ACC电源中。（请提前准备好适配车辆的OBD转接线）'
        },
        {
          name: '3',
          title: '无法找到OBD蓝牙信号?',
          answer: '确认接线正确后，尝试重新开启蓝牙设置中的蓝牙开关。如仍无法获取尝试使用手机搜索OBDⅡ名称的蓝牙信号，如手机端也无法找到请联系售后客服进行处理。'
        },
        {
          name: '4',
          title: 'OBD如何进行连接?',
          answer: 'OBD安装完成后，进入OBD页面【点击连接图标】接入【OBDⅡ】名称的蓝牙，【点击“已连接”】图标系统将自动跳转至OBD页面。'
        },
        {
          name: '5',
          title: 'OBD蓝牙配对哪个呢?',
          answer: 'OBD安装完成后请选择名称为【OBDⅡ】的蓝牙进行连接。'
        },
        {
          name: '6',
          title: '为什么我的OBD没有XXX数据？',
          answer: 'OBD数据的获取量根据每款车型所具有的传感器数量及类型不同而决定, 如OBD无法获取某项数据则代表该车ECU无法提供该类型数据。'
        },
        {
          name: '7',
          title: '设备无法上网?',
          answer: '1. 请查询流量是否已用完          2. 确认移动数据开关已开启, 如仍无法解决请尝试重新拔插SIM卡后重启。'
        },
        {
          name: '8',
          title: '为什么唯独QQ音乐不能联网无法正常操作？',
          answer: '请进入CHIGEE公众号, 点击流量充值, 输入卡号后进行实名制认证。'
        },
        {
          name: '9',
          title: '为什么唯独QQ音乐不能联网无法正常操作？',
          answer: '请进入CHIGEE公众号, 点击流量充值, 输入卡号后进行实名制认证。'
        },
        {
          name: '10',
          title: '导航是用的什么流量呢？',
          answer: '在线导航使用的是车机SIM卡的流量。'
        },
        {
          name: '11',
          title: '在无网络地区如何进行导航？',
          answer: '如需进入无人区请提前下载该地区离线地图。'
        }
      ],
      erwei: require('../../../public/images/support/erwei.png')
    }
  },
  methods: {
    // 分段器
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  background-color: #f8f8f8;
}
.navbar {
  height: 40px;
  padding: 15px 25% 0;
  background: #dfdfdf;
}
.resize {
  width: 100%;
  height: 100%;
}
.intro {
  padding-top: 60px;
  height: 200px;
}
.intro_goods {
  display: flex;
  justify-content: space-between;
  .goods_1_link {
    width: 120px;
    height: 35px;
    background: url('../../../public/images/support/box.png') no-repeat;
    background-size: 100% 100%;
    line-height: 35px;
    text-align: center;
  }
}
.main {
  text-align: left;
  ::v-deep .el-collapse-item__header {
    background-color: #f8f8f8;
    height: 66px;
    font-size: 15px;
  }
  ::v-deep .el-collapse-item__wrap {
    background-color: #f8f8f8;
  }
  ::v-deep .el-collapse-item__content {
    font-size: 15px;
  }
}
.main_btm {
  height: 400px;
  text-align: center;
  // background-color: pink;
  padding-top: 100px;
}
</style>